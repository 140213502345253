<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Kode *</label>
          <vs-input class="w-full" v-model="data.kode" disabled/>
        </div>
        <div class="vx-col sm:w-9/12 w-full">
          <label class="ml-1 text-xs">Nama *</label>
          <vs-input class="w-full" v-model="data.nama"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Identitas</label>
          <vs-input class="w-full" v-model="data.no_identitas"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Gender</label>
          <vs-input class="w-full" :value="data.gender === 'L' ? 'Laki-laki' : 'Perempuan'" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tempat Lahir</label>
          <vs-input class="w-full" v-model="data.tmp_lahir"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tanggal Lahir</label>
          <flat-pickr class="w-full" v-model="data.tgl_lahir"></flat-pickr>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-phone" v-model="data.telp"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">HP *</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-smartphone" v-model="data.hp" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">E-mail *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-mail" v-model="data.email"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Agama</label>
          <vs-input class="w-full" v-model="data.agama"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kelurahan</label>
          <vs-input class="w-full" v-model="data.kelurahan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kota</label>
          <vs-input class="w-full" v-model="data.kota"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kode Pos</label>
          <vs-input class="w-full" type="number" v-model="data.pos"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tujuan Pembelian</label>
          <vs-input class="w-full" v-model="data.tujuan_pembelian"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Domisili</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.domisili"/>
        </div>
        <div class="vx-col sm:w-9/12 w-full">
          <label class="ml-1 text-xs">Alamat *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Sumber Dana</label>
          <vs-input class="w-full" v-model="data.sumber_dana"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Nikah</label>
          <vs-input class="w-full" v-model="data.status_nikah"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Pasangan</label>
          <vs-input class="w-full" v-model="data.nama_pasangan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Tempat Tinggal</label>
          <vs-input class="w-full" v-model="data.status_tmptgl"/>
        </div>
      </div>

      <vs-divider> Pekerjaan </vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Profesi *</label>
          <v-select :options="profesis" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_profesi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Profesi</label>
          <vs-input class="w-full" v-model="data.status_profesi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jabatan</label>
          <vs-input class="w-full" v-model="data.jabatan"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">NPWP</label>
          <vs-input class="w-full" type="number" v-model="data.npwp"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat NPWP</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_npwp"/>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Surat</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_surat"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Rekening</label>
          <vs-input class="w-full" type="number" v-model="data.no_rekening"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Instansi</label>
          <vs-input class="w-full" v-model="data.nama_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp. Instansi</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-phone" v-model="data.telp_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Fax Instansi</label>
          <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-printer" v-model="data.fax_instansi"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Instansi</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">CP Instansi</label>
          <vs-input class="w-full" v-model="data.cp_instansi"/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jenis Usaha</label>
          <vs-input class="w-full" v-model="data.jenis_usaha"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tetap</label>
          <v-money class="w-full" v-model="data.pengh_tetap"/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tambahan</label>
          <v-money class="w-full" v-model="data.pengh_tambah"/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Bulanan</label>
          <v-money class="w-full" v-model="data.pengh_bulanan"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import ProfesiRepository from '@/repositories/master/profesi-repository'
import CustomerRepository from '@/repositories/master/customer-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  name: 'TabCustomer',
  props: ['isActive'],
  components: {
    VMoney,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.initData()
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    idCustomer () {
      return this.storeBookingDetail.booking.id_customer
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      errors: null,
      data: {},
      genders: [{ gender: 'L', nama: 'Laki-Laki' }, { gender: 'P', nama: 'Perempuan' }],
      profesis: []
    }
  },
  methods: {
    initData () {
      this.getCustomer()
      this.getAllProfesi()
      this.isDataInited = true
    },

    getCustomer () {
      this.isLoading = true
      CustomerRepository.show(this.idCustomer)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      CustomerRepository.update(this.data)
        .then(response => {
          this.data = response.data.data // latest data in db
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
            this.notifyError('Data tidak valid.')
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
